import React, { useEffect, useState } from 'react';
import Cookie from 'js-cookie';
import { useLayoutContext } from '@ott/layout-context';

import { lang } from '@ott/l10n';

import { MetaWebBanner as MetaWebBannerComponent } from '@ott/meta-web-banner';

import { checkIsMetaWebBanner } from 'src/utility/abTest';
import { isOttBrand } from 'src/helpers/ottBrand';

const showMetaWebBanner = checkIsMetaWebBanner();

const MetaWebBanner = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const { isMobile } = useLayoutContext();

  useEffect(() => {
    const modalShown = sessionStorage.getItem('metaWebBannerShown');

    if (!modalShown) {
      setIsModalVisible(true);
      sessionStorage.setItem('metaWebBannerShown', 'true');

      // для того чтоб не было прыжка в анимации появления баннера
      setTimeout(() => {
        setIsMounted(true);
      }, 500);
    }
  }, []);


  const metricParams = {
    lang,
    page: 'index_avia',
    product: 'avia',
    referrer_mrk: Cookie.get('referrer_mrk'),
    isAdaptive: isMobile,
  };

  if (
    typeof window === 'undefined' ||
    lang !== 'ru' ||
    __WHITE_LABEL__ ||
    isOttBrand() ||
    !showMetaWebBanner ||
    !isMobile
  ) {
    return null;
  }

  return (
    <MetaWebBannerComponent
      isOpen={isModalVisible && isMounted}
      onIsOpenChange={setIsModalVisible}
      metricParams={metricParams}
    />
  );
};

export default MetaWebBanner;
