import React from 'react';
import { useSelector } from 'react-redux';

import Footer from '@ott/footer';
import { lang } from '@ott/l10n';

import { getOTTBrandDomain, isOttBrand } from 'src/helpers/ottBrand';

type Props = {
  className: string;
  config: {
    partner?: { [key: string]: any };
    product: string;
    footer?:
      | {
          preCopyright?: React.ReactNode;
          appLinks: {
            android: string;
            ios: string;
            huawei?: string;
          };
        }
      | Record<string, never>;
  };
  languageSwitcher: React.ReactNode | null;
};

const IndexFooter = (props: Props) => {
  const { footer = {}, partner, product } = props.config;

  const ssr = useSelector<boolean>((state) => state.common.ssr);

  const ottBrandDomain = getOTTBrandDomain();
  const ottBrandSupportUrl = `https://support.onetwotrip.com/hc/ru/articles/4410607185042?utm_source=${ottBrandDomain}.onetwotrip.com`;

  if (ssr) {
    return null;
  }

  /*
    Добавляем в футер key - для ререндера футера и дальнейшего обновления параметров для
     скачивания моб. приложения при переключении вертикали на первой странице
   */

  return (
    <Footer
      key={product}
      className={props.className}
      hasMobileApps={!partner}
      hasSocials={!partner}
      hasPayments={!partner}
      hasEmailSubscription={!partner}
      lang={lang}
      languageSwitcher={props.languageSwitcher}
      preCopyright={footer?.preCopyright}
      hostname="https://www.onetwotrip.com"
      partner={partner}
      appLinks={footer?.appLinks}
      ottBrandSupportUrl={isOttBrand() && ottBrandSupportUrl}
      specificLocators="index-footer"
    />
  );
};

export default IndexFooter;
